import React from "react";
import { css } from "@emotion/react";
import { connect } from "react-redux";
import { graphql, navigate, withPrefix } from "gatsby";
import { getUserLangKey } from "ptz-i18n";

import * as Action from "libs/redux/action";

import * as Libs from "libs";
class RedirectIndex extends React.PureComponent {
	constructor(args) {
		super(args);

		// Skip build, Browsers only

		if (typeof window !== "undefined") {
			const { langs, defaultLangKey } = args.data.site.siteMetadata.languages;

			const langKey = getUserLangKey(langs, defaultLangKey);

			const currentLangKey = Libs.Common.Func.getLangKey();
			const homeUrl = withPrefix(`/${langKey}/`);
			const { dispatch } = this.props;
			if (currentLangKey !== langKey) {
				Libs.Common.Config.setCurrentLang(langKey);
				dispatch(Action.LangChanged(langKey));
			}
			navigate(homeUrl);
		}
	}

	render() {
		const containerCss = () => {
			return css`
				${Libs.Styles.Mixin.containerCss()}
				text-align: center;
			`;
		};
		const ulCss = () =>
			css`
				${Libs.Common.Func.getMqVwValue("margin-top", 50)}
				>li:not(:last-child) {
					${Libs.Common.Func.getMqVwValue("margin-bottom", 30)}
				}
			`;

		return (
			<section
				className={"project-container"}
				css={containerCss}
				ref={this.container}
			>
				<Libs.Common.SEO.SEO />
				<Libs.Atoms.H2
					styles={{
						pcFontSize: 34,
						spFontSize: 28,
						pcLineHeight: 34,
						spLineHeight: 34,
						text_align: "center",
					}}
				>
					Choose your language.
				</Libs.Atoms.H2>
				<ul css={ulCss}>
					<li>
						<Libs.Atoms.TriggerLink to={"/ja/"}>
							<Libs.Atoms.Span
								styles={{
									pcFontSize: 24,
									spFontSize: 22,
									pcLineHeight: 24,
									spLineHeight: 22,
								}}
							>
								Japanese
							</Libs.Atoms.Span>
						</Libs.Atoms.TriggerLink>
					</li>
					<li>
						<Libs.Atoms.TriggerLink to={"/en/"}>
							<Libs.Atoms.Span
								styles={{
									pcFontSize: 24,
									spFontSize: 22,
									pcLineHeight: 24,
									spLineHeight: 22,
								}}
							>
								English
							</Libs.Atoms.Span>
						</Libs.Atoms.TriggerLink>
					</li>
				</ul>
			</section>
		);
	}
}
export const pageQuery = graphql`
	query IndexQuery {
		site {
			siteMetadata {
				languages {
					defaultLangKey
					langs
				}
			}
		}
	}
`;

export default connect()(RedirectIndex);
